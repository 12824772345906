export class HttpStatusCodes {
    static readonly Status100Continue = 100;
    static readonly Status101SwitchingProtocols = 101;
    static readonly Status102Processing = 102;
    static readonly Status200OK = 200;
    static readonly Status201Created = 201;
    static readonly Status202Accepted = 202;
    static readonly Status203NonAuthoritative = 203;
    static readonly Status204NoContent = 204;
    static readonly Status205ResetContent = 205;
    static readonly Status206PartialContent = 206;
    static readonly Status207MultiStatus = 207;
    static readonly Status208AlreadyReported = 208;
    static readonly Status226IMUsed = 226;
    static readonly Status300MultipleChoices = 300;
    static readonly Status301MovedPermanently = 301;
    static readonly Status302Found = 302;
    static readonly Status303SeeOther = 303;
    static readonly Status304NotModified = 304;
    static readonly Status305UseProxy = 305;
    static readonly Status306SwitchProxy = 306;
    static readonly Status307TemporaryRedirect = 307;
    static readonly Status308PermanentRedirect = 308;
    static readonly Status400BadRequest = 400;
    static readonly Status401Unauthorized = 401;
    static readonly Status402PaymentRequired = 402;
    static readonly Status403Forbidden = 403;
    static readonly Status404NotFound = 404;
    static readonly Status405MethodNotAllowed = 405;
    static readonly Status406NotAcceptable = 406;
    static readonly Status407ProxyAuthenticationRequired = 407;
    static readonly Status408RequestTimeout = 408;
    static readonly Status409Conflict = 409;
    static readonly Status410Gone = 410;
    static readonly Status411LengthRequired = 411;
    static readonly Status412PreconditionFailed = 412;
    static readonly Status413RequestEntityTooLarge = 413;
    static readonly Status413PayloadTooLarge = 413;
    static readonly Status414RequestUriTooLong = 414;
    static readonly Status414UriTooLong = 414;
    static readonly Status415UnsupportedMediaType = 415;
    static readonly Status416RequestedRangeNotSatisfiable = 416;
    static readonly Status416RangeNotSatisfiable = 416;
    static readonly Status417ExpectationFailed = 417;
    static readonly Status418ImATeapot = 418;
    static readonly Status419AuthenticationTimeout = 419;
    static readonly Status421MisdirectedRequest = 421;
    static readonly Status422UnprocessableEntity = 422;
    static readonly Status423Locked = 423;
    static readonly Status424FailedDependency = 424;
    static readonly Status426UpgradeRequired = 426;
    static readonly Status428PreconditionRequired = 428;
    static readonly Status429TooManyRequests = 429;
    static readonly Status431RequestHeaderFieldsTooLarge = 431;
    static readonly Status451UnavailableForLegalReasons = 451;
    static readonly Status500InternalServerError = 500;
    static readonly Status501NotImplemented = 501;
    static readonly Status502BadGateway = 502;
    static readonly Status503ServiceUnavailable = 503;
    static readonly Status504GatewayTimeout = 504;
    static readonly Status505HttpVersionNotsupported = 505;
    static readonly Status506VariantAlsoNegotiates = 506;
    static readonly Status507InsufficientStorage = 507;
    static readonly Status508LoopDetected = 508;
    static readonly Status510NotExtended = 510;
    static readonly Status511NetworkAuthenticationRequired = 511;
    static readonly Msg801Level16State1 = 801;
}